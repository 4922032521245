import { DownloadStatus, NotificationType } from './export-files.models';

export const MOCK_SITE_ID = 'a414aaa8-0974-4155-a83c-1aa68ac6d2be';
export const FILE_STATUS_MOCK = {
  files: [
    {
      category: 'Export',
      fileId: '05c13918-97e8-11ed-b37a-e502320d6992',
      fileSize: 0,
      importedOn: '2023-01-19T10:57:10.244880Z',
      name: 'InsightsCSVExport.csv',
      status: DownloadStatus.EXPORT_FAILED,
      isReadyForSync: true
    },
    {
      category: 'Export',
      fileId: '88c14508-971e-11ed-8093-735514f14470',
      fileSize: 38449487,
      importedOn: '2023-01-18T10:54:51.695620Z',
      name: 'SiteSurface-18-Jan-2023-03-54-51.23511.csv',
      status: DownloadStatus.READY_FOR_DOWNLOAD,
      isReadyForSync: true
    },
    {
      category: 'Export',
      fileId: '88c14508-971e-11ed-8093-735514f14470',
      fileSize: 38449487,
      importedOn: '2023-01-18T10:54:51.695620Z',
      name: 'SiteSurface-18-Jan-2023-03-54-51.23511.csv',
      status: DownloadStatus.EXPORT_INPROGRESS,
      isReadyForSync: true
    }
  ],
  siteId: MOCK_SITE_ID
};

export const FileStatusRequestMock = {
  siteId: 12345,
  siteGuid: MOCK_SITE_ID,
  fileNames: 'SiteSurface-18-Jan-2023-03-54-51.23511.zip,SiteSurface-19-Jan-2023-03-54-51.23511.zip'
};

export const FileDataMock = {
  downloadUrl:
    'https://pfm-surfacemgnt-fileupload-s3-preprod.s3-accelerate.amazonaws.com/a414aaa8-0974-4155-a83c-1aa68ac6d2be/export/SiteSurface-18-Jan-2023-03-49-21.21969.csv',
  fileName: 'SiteSurface-18-Jan-2023-03-49-21.21969.csv',
  siteName: 'Sydney'
};

export const MockMachinePassExportData = {
  siteId: MOCK_SITE_ID,
  ucid: '1ee8ec0c-c2e0-4121-87eb-de8f7ec2dda0',
  siteGuid: '10b9e122-bad0-51cb-b012-4fb848cf68d3',
  siteName: 'ABC',
  assetTag: 'Compact',
  isLocalProjection: true,
  timeFormat: 'hours24',
  dateFormat: 'MMM DD YYYY',
  decimalSeparator: 'Period',
  exportType: 'zip',
  fileName: 'SiteSurface-18-Jan-2023-03-54-51.23511.zip',
  startTime: '2023-03-15T10:15:46.384208Z',
  endTime: '2023-03-15T10:55:46.384208Z',
  columnHeader: true,
  isCompressionRequired: true,
  speedUnitOfMeasure: 'mph',
  reportType: 'machinepass',
  temperatureUnitOfMeasure: 'Celsius',
  elevationUnitOfMeasure: 'us_feet',
  source: 'SM',
  handlerUrl: '/catDigital/surfaceManagement/files/v1/files/exportData'
};

export const ExportFilesMockStore = {
  exportFiles: {
    activeExports: {
      ids: [],
      entities: []
    }
  },
  environment: {
    env: 'dev'
  }
};

export const MockNotificationData = {
  id: 0,
  title: 'Download Failed',
  message: 'Surface file couldn’t be downloaded.',
  type: NotificationType.error,
  payload: MockMachinePassExportData
};
