import { createAction, props } from '@ngrx/store';
import { ExportData, FileStatusData, FileStatusRequest } from '../export-files.models';

export const exportData = createAction('[ExportFiles] Export Data Action', props<{ exportData: ExportData }>());

export const addFileExport = createAction('[ExportFiles] Add File Export', props<{ download: ExportData }>());

export const removeFileExport = createAction('[ExportFiles] Remove File Export', props<{ payload: ExportData }>());

export const getFileStatus = createAction('[ExportFiles] Get File Status', props<{ fileStatusRequest: FileStatusRequest }>());

export const getFileStatusSuccess = createAction('[ExportFiles] Get File Status Success', props<{ fileStatusList: FileStatusData[] }>());

export const getDownloadUrl = createAction('[ExportFiles] Get Download URL', props<{ exportData: ExportData }>());

export const getDownloadUrlSuccess = createAction('[ExportFiles] Get Download URL Success');
