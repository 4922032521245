import { MessageBarHorizontalPosition, MessageBarVerticalPosition } from '@cat-digital-workspace/shared-ui-core/message';
import { DownloadStatus } from './export-files.models';

export const API_ENDPOINTS = {
  get_files: '/api/v1/organization/jobsite/{site_id}/files',
  get_export_files: '/catDigital/surfaceManagement/files/v1/files/exportData',
  get_download_url: '/catDigital/surfaceManagement/files/v1/files/'
};

export const NODE_ENDPOINTS = {
  HANDLER_URL: 'handlerURL',
  EXPORT_FILE_DATA: '/exportFileData',
  GET_PRESIGNED_URL: '/getExportPresignedUrl',
  DOWNLOAD_FILE_FROM_S3: '/downloadFileFromS3',
  EXPORT_FILE_STATUS: '/getExportFileStatus'
};

export const POLLING_INTERVAL = 30000;

export const horizontalPosition: MessageBarHorizontalPosition = 'center';
export const verticalPosition: MessageBarVerticalPosition = 'top';

export const TOASTER_CONFIG = {
  TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
  },
  HOST_TYPE: {
    OVERLAY: 'overlay',
    INLINE: 'inline'
  },
  H_POSITION: horizontalPosition,
  V_POSITION: verticalPosition
};

export enum EXPORT_DOWNLOAD {
  URL_KEY = 'url',
  CONTENT_TYPE_KEY = 'Content-Type',
  CONTENT_TYPE = 'application/zip; charset=utf-8',
  RESPONSE_TYPE = 'arraybuffer',
  BLOB_TYPE = 'application/zip'
}

export const PROGRESS_STATUS = [DownloadStatus.EXPORT_INPROGRESS, DownloadStatus.DOWNLOAD_IN_PROGRESS];
