import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromExportFiles from './+state/export-files.reducer';
import { ExportFilesEffects } from './+state/export-files.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromExportFiles.EXPORT_FILES_FEATURE_KEY, fromExportFiles.reducer),
    EffectsModule.forFeature([ExportFilesEffects])
  ]
})
export class GlobalFileExportPollingModule {}
