export interface ExportFilesEntity {
  id: string | number; // Primary ID
  name: string;
}

export interface ExportDataPayload {
  siteId: number | string;
  exportType: string;
  fileName: string;
  source: string; // SM | CATOS
  handlerUrl: string;
}

// Kept all optional properties in this interface
export interface ExportData extends ExportDataPayload {
  ucid?: string;
  siteGuid?: string;
  siteName?: string;
  fileId?: string;
  status?: DownloadStatus;
  fileIds?: string[];
  devicetype?: string[];
  startTime?: string;
  endTime?: string;
  isLocalProjection?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  decimalSeparator?: string;
  assetTag?: string;
  assetId?: string;
  columnSeparator?: string;
  downloadSuccess?: boolean;
  columnHeader?: boolean;
  isCompressionRequired?: boolean;
  reportType?: string;
  sendReportTypeToPayload?: boolean;
  speedUnitOfMeasure?: string;
  temperatureUnitOfMeasure?: string;
  elevationUnitOfMeasure?: string;
  elevation?: string;
  assets?: string;
  volumeUnitOfMeasure?: string;
  weightUnitOfMeasure?: string;
  densityUnitOfMeasure?: string;
  boundCoordinates?: string;
  columnOrder?: string;
  message?: string;
  navigate?: { isNavigate: boolean; navigateTo: string };
  allowMultipleDownload?: boolean;
}

export interface ExportDataResponse {
  status: string;
}

export interface FileStatusRequest {
  siteId: number | string;
  siteGuid: string;
  fileNames: string;
}

export interface FileStatusData {
  category: string;
  fileId: string;
  fileSize: number;
  importedOn: string;
  name: string;
  status: DownloadStatus;
  isReadyForSync: boolean;
}

export interface FileStatusResponse {
  files: FileStatusData[];
}

export interface FileData {
  downloadUrl: string;
  fileName: string;
  siteName: string;
}

export enum DownloadStatus {
  'EXPORT_INPROGRESS' = 'EXPORT_IN_PROGRESS',
  'READY_FOR_DOWNLOAD' = 'READY_FOR_DOWNLOAD',
  'EXPORT_FAILED' = 'EXPORT_FAILED',
  'FILE_ID_FAILED' = 'FILE_ID_FAILED',
  'DOWNLOAD_IN_PROGRESS' = 'DOWNLOAD_IN_PROGRESS'
}

export interface AppNotification {
  title?: string;
  message?: string;
  type: NotificationType;
  id?: number;
  payload: ExportData;
}

export enum NotificationType {
  warning = 'warning',
  error = 'error'
}
