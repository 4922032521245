import { groupBy } from '@Terra/shared/widgets/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageBar } from '@cat-digital-workspace/shared-ui-core/message';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription, interval } from 'rxjs';
import { filter, map, retry, startWith, takeUntil } from 'rxjs/operators';
import * as ACTIONS from './+state/export-files.actions';
import * as SELECTORS from './+state/export-files.selectors';
import {
  AppNotification,
  DownloadStatus,
  ExportData,
  ExportDataPayload,
  ExportDataResponse,
  FileData,
  FileStatusRequest,
  FileStatusResponse,
  NotificationType
} from './export-files.models';
import {
  API_ENDPOINTS,
  EXPORT_DOWNLOAD,
  NODE_ENDPOINTS,
  POLLING_INTERVAL,
  PROGRESS_STATUS,
  TOASTER_CONFIG
} from './global-file-export.config';

@Injectable({
  providedIn: 'root'
})
export class GlobalFileExportPollingService {
  exportData$ = new Subject<ExportData>();
  pausePolling$ = new Subject<void>();
  failedExports$ = new Subject<AppNotification[]>();
  fileExports$ = new BehaviorSubject<ExportData[]>([]);
  removeNotification$ = new Subject<ExportData>();
  tryAgain$ = new Subject<AppNotification>();
  failedExports: ExportData[] = [];
  pollingStarted = false;
  isDownloading = false;
  activeDownloads: ExportData[] = [];
  subs = new Subscription();
  fileIdInProgress: string[] = [];
  envObj: any;
  constructor(private readonly http: HttpClient, private readonly store: Store<any>, public messageBar: MessageBar) {
    this.subscribeExportData();
    this.subscribeActiveDownloads();
    this.subscribeTryAgain();
    this.subscribeRemoveNotification();
    this.subscribeEnvironmentDetails();
  }

  exportData(requestParams: ExportData): Observable<ExportDataResponse> {
    const {
      ucid,
      siteGuid,
      fileId,
      status,
      navigate,
      message,
      siteName,
      handlerUrl,
      source,
      reportType,
      sendReportTypeToPayload,
      allowMultipleDownload,
      ...requestPayload
    } = requestParams;
    requestPayload['siteId'] = siteGuid;
    if (sendReportTypeToPayload) {
      requestPayload['reportType'] = reportType;
    }
    const body = {
      params: requestPayload as ExportDataPayload,
      handlerURL: handlerUrl,
      customheaders: JSON.stringify({
        'Content-Type': 'application/json'
      })
    };

    const params = new HttpParams().set('source', source);
    return this.http.post<ExportDataResponse>(NODE_ENDPOINTS.EXPORT_FILE_DATA, body, { params });
  }

  getFileStatus(request: FileStatusRequest): Observable<FileStatusResponse> {
    const params = new HttpParams();
    const handlerURL = API_ENDPOINTS.get_files.replace('{site_id}', `${request.siteId}`);
    const body = {
      handlerURL,
      params: { name: request.fileNames },
      customheaders: JSON.stringify({ 'Content-Type': 'application/json', 'X-Cat-API-Tracking-Id': request.siteGuid })
    };
    return this.http.post<FileStatusResponse>(NODE_ENDPOINTS.EXPORT_FILE_STATUS, body, { params }).pipe(retry(2));
  }

  getPresignedURL(requestParams: ExportData): Observable<FileData> {
    const siteGuid = requestParams.siteGuid;
    const handlerURL = `${API_ENDPOINTS.get_download_url}${requestParams.fileId}`;
    const params = new HttpParams().append(NODE_ENDPOINTS.HANDLER_URL, handlerURL).append('siteId', siteGuid);
    return this.http.get<FileData>(NODE_ENDPOINTS.GET_PRESIGNED_URL, { params });
  }

  subscribeExportData(): void {
    const exportDataSubs = this.exportData$.subscribe((exportData: ExportData) => {
      this.showToasterMessage(exportData?.message);
      this.dispatchExportData(exportData);
    });
    this.subs.add(exportDataSubs);
  }

  subscribeEnvironmentDetails(): void {
    const environmentDataSubs = this.store
      .select((state: any) => state.environment)
      .subscribe(response => {
        if (response) {
          this.envObj = response;
        }
      });
    this.subs.add(environmentDataSubs);
  }

  subscribeActiveDownloads(): void {
    const activeDownloadsSubs = this.store.select(SELECTORS.getActiveFileExports).subscribe((fileExports: ExportData[]) => {
      this.fileExports$.next(fileExports);
      this.activeDownloads = fileExports;
      this.checkToastMessage();
      this.checkPolling();
      this.checkFailedExports();
      this.checkFilesReady();
    });
    this.subs.add(activeDownloadsSubs);
  }

  checkPolling(): void {
    const inProgressExports = this.activeDownloads.filter(activeExport => PROGRESS_STATUS.includes(activeExport.status));
    if (inProgressExports.length && !this.pollingStarted) {
      this.startPolling(POLLING_INTERVAL);
      this.pollingStarted = true;
    } else if (!inProgressExports.length && this.pollingStarted) {
      this.pausePolling$.next();
      this.pollingStarted = false;
    }
  }

  startPolling(duration: number): void {
    const polling = interval(duration)
      .pipe(startWith(0), takeUntil(this.pausePolling$))
      .subscribe(() => {
        const inProgressExports = this.activeDownloads.filter(activeExport => PROGRESS_STATUS.includes(activeExport.status));
        const filesBySites = groupBy(inProgressExports, element => element.siteId.toString());
        for (const site in filesBySites) {
          const fileNames: string[] = [];
          const { siteId, siteGuid } = filesBySites[site][0];
          filesBySites[site].forEach(file => {
            fileNames.push(file.fileName);
          });
          this.store.dispatch(
            ACTIONS.getFileStatus({ fileStatusRequest: { siteId, siteGuid, fileNames: fileNames.join(',') } as FileStatusRequest })
          );
        }
      });
    this.subs.add(polling);
  }

  checkToastMessage(): void {
    const inProgressExports = this.getStatus(PROGRESS_STATUS);
    if (!inProgressExports.length) {
      this.hideToasterMessage();
    }
  }

  checkFailedExports(): void {
    const failedExports = this.activeDownloads.filter(activeExport =>
      [DownloadStatus.EXPORT_FAILED, DownloadStatus.FILE_ID_FAILED].includes(activeExport.status)
    );
    const payload: AppNotification[] = failedExports.map((notification: ExportData, index) => {
      if (notification.status === DownloadStatus.FILE_ID_FAILED) {
        this.fileIdInProgress = this.fileIdInProgress.filter(fileName => fileName !== notification.fileName);
      }
      return {
        id: index + 1,
        payload: notification,
        type: NotificationType.error
      };
    });
    this.failedExports$.next(payload);
  }

  getStatus(status: DownloadStatus[]): ExportData[] {
    return this.activeDownloads.filter(activeExport => status.includes(activeExport.status));
  }

  checkFilesReady(): void {
    this.activeDownloads.forEach(activeExport => {
      if (activeExport.status === DownloadStatus.READY_FOR_DOWNLOAD && !this.fileIdInProgress.includes(activeExport.fileName)) {
        this.fileIdInProgress.push(activeExport.fileName);
        this.dispatchFileID(activeExport);
      }
    });
  }

  showToasterMessage(message = 'insights.mapDownload.downloadProgress'): void {
    this.messageBar.open(translate(message), TOASTER_CONFIG.TYPE.INFO, [], {
      hostType: TOASTER_CONFIG.HOST_TYPE.OVERLAY,
      horizontalPosition: TOASTER_CONFIG.H_POSITION,
      verticalPosition: TOASTER_CONFIG.V_POSITION
    });
  }

  hideToasterMessage(): void {
    this.messageBar.dismiss();
  }

  subscribeTryAgain(): void {
    this.tryAgain$.subscribe(event => {
      if (event.payload.navigate?.isNavigate) {
        const navigationLink = `${event.payload.navigate.navigateTo}/${event.payload.siteName}`;
        window.location.href = navigationLink;
      } else {
        this.tryAgain(event.payload);
      }
    });
  }

  tryAgain(event: ExportData): void {
    this.showToasterMessage();
    switch (event.status) {
      case DownloadStatus.EXPORT_FAILED: {
        this.dispatchExportData(event);
        break;
      }
      case DownloadStatus.FILE_ID_FAILED: {
        this.dispatchFileID(event);
      }
    }
  }

  subscribeRemoveNotification(): void {
    this.removeNotification$.subscribe(notification => this.dispatchRemoveNotification(notification));
  }

  dispatchRemoveNotification(payload: ExportData): void {
    this.failedExports = this.failedExports.filter(exports => exports.fileName !== payload.fileName);
    this.fileIdInProgress = this.fileIdInProgress.filter(fileName => fileName !== payload.fileName);
    this.store.dispatch(ACTIONS.removeFileExport({ payload }));
  }

  dispatchExportData(exportData: ExportData): void {
    this.store.dispatch(ACTIONS.exportData({ exportData }));
  }

  dispatchFileID(exportData: ExportData): void {
    this.store.dispatch(ACTIONS.getDownloadUrl({ exportData }));
  }

  downloadFile(exportData: ExportData, response: FileData): void {
    if (!exportData?.allowMultipleDownload && this.envObj.env !== 'local') {
      this.fileDownload(response.downloadUrl, exportData.fileName);
      this.removeNotification$.next({ ...exportData, downloadSuccess: true });
      this.isDownloading = false;
    } else {
      const body = {
        url: response.downloadUrl,
        type: EXPORT_DOWNLOAD.CONTENT_TYPE,
        resType: 'arraybuffer'
      };
      this.http
        .post(NODE_ENDPOINTS.DOWNLOAD_FILE_FROM_S3, body, { responseType: 'blob' as 'json' })
        .pipe(
          filter(res => !!res),
          retry(3),
          map(res => new Blob([res as any], { type: EXPORT_DOWNLOAD.CONTENT_TYPE }))
        )
        .subscribe({
          next: data => {
            this.fileDownloadforOtherBrowsers(data, exportData.fileName);
            this.removeNotification$.next({ ...exportData, downloadSuccess: true });
          },
          error: error => this.removeNotification$.next({ ...exportData, downloadSuccess: true })
        });
    }
  }

  fileDownload(url, filename): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    this.isDownloading = true;
    link.click();
    link.remove();
  }
  fileDownloadforOtherBrowsers(_blob, filename): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(_blob);
    link.download = filename;
    link.click();
    link.remove();
  }

  cancelPolling(): void {
    if (this.pollingStarted) {
      this.pausePolling$.next();
      this.pollingStarted = false;
      this.hideToasterMessage();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
