import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DownloadStatus } from '../export-files.models';
import { GlobalFileExportPollingService } from '../global-file-export-polling.service';
import * as ACTIONS from './export-files.actions';

@Injectable()
export class ExportFilesEffects {
  exportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.exportData),
      mergeMap(action =>
        this.fileExportService.exportData(action.exportData).pipe(
          map(status => ACTIONS.addFileExport({ download: { ...action.exportData, status: DownloadStatus.EXPORT_INPROGRESS } })),
          catchError(() =>
            of(
              ACTIONS.addFileExport({
                download: { ...action.exportData, status: DownloadStatus.EXPORT_FAILED }
              })
            )
          )
        )
      )
    )
  );

  fileStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.getFileStatus),
      mergeMap(action =>
        this.fileExportService.getFileStatus(action.fileStatusRequest).pipe(
          map(response => ACTIONS.getFileStatusSuccess({ fileStatusList: response.files })),
          catchError(() => of(ACTIONS.getFileStatusSuccess({ fileStatusList: [] })))
        )
      )
    )
  );

  fileDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACTIONS.getDownloadUrl),
      mergeMap(action =>
        this.fileExportService.getPresignedURL(action.exportData).pipe(
          map(response => {
            this.fileExportService.downloadFile(action.exportData, response);
            return ACTIONS.getDownloadUrlSuccess();
          }),
          catchError(() =>
            of(
              ACTIONS.addFileExport({
                download: { ...action.exportData, status: DownloadStatus.FILE_ID_FAILED }
              })
            )
          )
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly fileExportService: GlobalFileExportPollingService) {}
}
