import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DownloadStatus, ExportData, FileStatusData } from '../export-files.models';
import * as ACTIONS from './export-files.actions';

export const EXPORT_FILES_FEATURE_KEY = 'exportFiles';

export interface ExportFilesState {
  activeExports: EntityState<ExportData>;
}

// Entity Adapter for active Downloads
export const activeFileExportsAdapter = createEntityAdapter<ExportData>({ selectId: download => download.fileName });

export const initialState: ExportFilesState = {
  activeExports: activeFileExportsAdapter.getInitialState({})
};

const exportFilesReducer = createReducer(
  initialState,
  on(ACTIONS.addFileExport, (state, { download }) => ({
    ...state,
    activeExports: activeFileExportsAdapter.upsertOne(download, state.activeExports)
  })),
  on(ACTIONS.getFileStatusSuccess, (state, { fileStatusList }) => ({
    ...state,
    activeExports: activeFileExportsAdapter.upsertMany(getFilesToUpdate(state.activeExports, fileStatusList), state.activeExports)
  })),
  on(ACTIONS.removeFileExport, (state, { payload }) => ({
    ...state,
    activeExports: activeFileExportsAdapter.removeOne(payload.fileName, state.activeExports)
  }))
);

export function getFilesToUpdate(activeExports: EntityState<ExportData>, fileStatusList: FileStatusData[]): ExportData[] {
  const filesToUpdate: any = [];
  fileStatusList.forEach(file => {
    if (file.status !== DownloadStatus.EXPORT_INPROGRESS && activeExports.entities[file.name]) {
      const activeExport = { ...activeExports.entities[file.name], fileId: file.fileId, status: file.status };
      filesToUpdate.push(activeExport);
    }
  });
  return filesToUpdate;
}

export function reducer(state: ExportFilesState, action: Action) {
  return exportFilesReducer(state, action);
}

export const { selectAll: activeFileExports } = activeFileExportsAdapter.getSelectors();
